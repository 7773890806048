import React from "react"
import { Slide } from "react-slideshow-image"
import DoorCloser from "../../images/hardware/door_closer.png"
import LeverLock from "../../images/hardware/lever_lock.png"
import ExitDevice from "../../images/hardware/exit_device.png"
import LCN from "../../images/hardware/lcn.png"
import MortiseLock from "../../images/hardware/mortise_lock.png"
import ElectricLock from "../../images/hardware/electric_lock.png"
import video1 from "../../images/hardware/video1.mp4"
import video2 from "../../images/hardware/video2.mp4"

const slideContent = [video1, video2]

const responsiveSettings = [
  {
    breakpoint: 768,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 4,
    },
  },
  {
    breakpoint: 0,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2,
    },
  },
]

const Hardware = () => {
  return (
    <div className="max-container mt-7 md:mt-14">
      <Slide>
        {slideContent.map((content, index) => (
          <div className="h-[250px] md:h-[400px] w-full" key={index}>
            <video
              className="h-full w-full object-cover rounded-2xl"
              src={content}
              autoPlay
              loop
              muted
            >
              Your browser does not support the video tag.
            </video>
          </div>
        ))}
      </Slide>

      <h1 className="text-2xl md:text-4xl font-donegal mt-10 md:mt-14">
        Commercial Door Hardware
      </h1>

      <section className="mt-12">
        <Slide
          indicators={true}
          infinite={true}
          autoplay={true}
          responsive={responsiveSettings}
        >
          <div className="text-center h-full flex flex-col justify-between">
            <img src={DoorCloser} className="w-[70%]  mx-auto" />
            <p className="text-sm md:text-base font-donegal font-semibold">
              Falcon SC60 Series Door Closer
            </p>
          </div>
          <div className="text-center h-full flex flex-col justify-between">
            <img src={LeverLock} className="w-[70%] mx-auto" />
            <p className="text-sm md:text-base font-donegal font-semibold">
              Falcon W-Series Lever Lock
            </p>
          </div>
          <div className="text-center h-full flex flex-col justify-between">
            <img src={ExitDevice} className="w-[70%] mx-auto" />
            <p className="text-sm md:text-base  font-donegal font-semibold">
              Von Duprin Exit Devices
            </p>
          </div>
          <div className="text-center h-full flex flex-col justify-between">
            <img src={LCN} className="w-[70%] mx-auto" />
            <p className="text-sm md:text-base  font-donegal font-semibold">
              LCN 4040XP
            </p>
          </div>
          <div className="text-center  h-full flex flex-col justify-between">
            <img src={MortiseLock} className="w-[70%] mx-auto" />
            <p className="text-sm md:text-base  font-donegal font-semibold">
              Schlage L-Series Mortise Lock
            </p>
          </div>
          <div className="text-center h-full flex flex-col justify-between">
            <img src={ElectricLock} className="w-[70%] mx-auto" />
            <p className="text-sm md:text-base  font-donegal font-semibold">
              Electric Lock
            </p>
          </div>
        </Slide>
      </section>

      <div className="flex items-center gap-6 mt-14 justify-center">
        <button className="mt-4 ml-6 py-2 px-8 text-white font-inter  ">
          <a href={`tel:"832 576 5814"`}>Call Us</a>
        </button>
        <button className="mt-4 ml-6 py-2 px-9 text-white font-inter  ">
          <a href="mailto:sales@stellardandh.com">Email</a>
        </button>
      </div>
    </div>
  )
}

export default Hardware
