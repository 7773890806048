import React from "react"
import { Slide } from "react-slideshow-image"
import "react-slideshow-image/dist/styles.css"
import { BiPhoneCall } from "react-icons/bi"
import { IoLocationOutline } from "react-icons/io5"
import SlideImage1 from "../../images/Home/SlideImage1.jpg"
import SlideImage2 from "../../images/Home/SlideImage2.jpg"
import SlideVideo from "../../images/Home/SlideVideo.mp4"

const slideContent = [
  {
    url: SlideImage1,
    type: "image",
  },
  {
    url: SlideVideo,
    type: "video",
  },
  {
    url: SlideImage2,
    type: "image",
  },
]

const Hero = () => {
  return (
    <div className="flex flex-col md:flex-row mt-4 md:mt-10 p-4 md:p-10 max-container">
      <div className="flex flex-col mx-auto items-center justify-center md:pr-10 order-2 md:order-1 mt-6 md:mt-0">
        <h1 className="font-donegal text-2xl md:text-4xl leading-normal">
          Commercial Doors and Hardware
        </h1>
        <p className="leading-6 font-inter mt-4">
          Stellar Doors & Hardware is a leading door distributor in the Houston,
          TX area, dedicated to providing top-quality products and exceptional
          service. Our extensive range includes hollow metal doors and frames,
          wood doors, laminate doors, traffic doors, interior aluminum frames,
          and more. Reach out to us today, and let our expertise and commitment
          to excellence help make your next project a success.
        </p>
        <div className="md:flex mt-8 gap-10">
          <div className="flex items-center gap-2">
            <BiPhoneCall size={30} className="text-blue-900" />
            <span>832 576 5814</span>
          </div>
          <div className="mt-4 md:mt-0 flex items-center gap-2">
            <IoLocationOutline size={30} className="text-blue-900" />
            <span>5858 Cunningham Rd Houston TX 77041</span>
          </div>
        </div>
      </div>
      <div className="w-full md:w-[50%] ml-auto order-1 md:order-2">
        <Slide>
          {slideContent.map((content, index) => (
            <div className="h-full md:h-[400px] w-full" key={index}>
              {content.type === "video" ? (
                <video
                  className="h-full w-full object-cover rounded-2xl"
                  src={content.url}
                  autoPlay
                  loop
                  muted
                >
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img
                  className="h-full w-full object-cover rounded-2xl"
                  src={content.url}
                  alt="slide content"
                />
              )}
            </div>
          ))}
        </Slide>
      </div>
    </div>
  )
}

export default Hero
