import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import Home from "./pages/Home"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import About from "./pages/About"
import Layout from "./pages/Layout"
import Products from "./pages/Products/Products"
import HollowMetalDoor from "./pages/Products/HollowMetalDoor"
import HollowMetalFrames from "./pages/Products/HollowMetalFrames"
import InteriorAlumnium from "./pages/Products/InteriorAlumnium"
import Hardware from "./pages/Products/Hardware"
import Wooddoor from "./pages/Products/WoodDoor"
import TrafficDoor from "./pages/Products/TrafficDoor"
import More from "./pages/More/More"
import InstallationVideos from "./pages/More/InstallationVideos"
import PrivacyPolicy from "./pages/More/PrivacyPolicy"
import IndustryLinks from "./pages/More/IndustryLinks"

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="products" element={<Products />} />
          <Route path="products" element={<Products />} />
          <Route
            path="/products/hollow-metal-doors"
            element={<HollowMetalDoor />}
          />
          <Route
            path="/products/hollow-metal-frames"
            element={<HollowMetalFrames />}
          />
          <Route
            path="/products/interior-alumnium-frames"
            element={<InteriorAlumnium />}
          />
          <Route path="/products/hardware" element={<Hardware />} />
          <Route path="/products/traffic-doors" element={<TrafficDoor />} />
          <Route path="/products/wood-doors" element={<Wooddoor />} />
          <Route path="/more" element={<More />} />
          <Route
            path="/more/installation-videos"
            element={<InstallationVideos />}
          />
          <Route path="/more/industry-link" element={<IndustryLinks />} />
          <Route path="/more/privacy-policy" element={<PrivacyPolicy />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
