import React from "react"
import new_image from "../../images/About/about_door.png"

const Hero = () => {
  return (
    <div className="mt-10 md:mt-20">
      <div className="flex flex-col md:flex-row">
        <img className="h-[400px] md:h-[600px] object-cover" src={new_image} />
        <div className="mt-10 md:mt-0 md:ml-10">
          <div className="border-b border-l border-[#276897] p-6">
            <h2 className="font-donegal text-3xl">
              Your trusted door and hardware store
            </h2>
            <p className="mt-6 font-donegal leading-relaxed text-[#6B6B6B]">
            Stellar Doors & Hardware is the leading choice in Houston for 
            top-quality doors and hardware. We offer a wide selection, including
             hollow metal doors, wood doors, laminate doors, traffic doors, and 
             interior aluminum frames. Our commitment to excellence and exceptional 
             service makes us the trusted choice for your next project. Reach out to 
             us today and experience the Stellar difference.
            </p>
          </div>
          <div className="mt-4 marker:md:mt-8 p-6">
            <h2 className="font-donegal text-2xl md:text-3xl">Our Story</h2>
            <p className="mt-6 font-donegal leading-relaxed text-[#6B6B6B]">
            We started with a dedication to providing 
            top-quality doors and hardware to the Houston area. Over the years,
             we've grown into a leading distributor, known for our extensive range 
             of products. Our commitment to excellence and exceptional service have been
              the cornerstones of our success. As we continue to evolve, our focus remains
               on helping our customers achieve their project goals with the best products
                and support available.
            </p>
          </div>
          <a href={`tel:"832 576 5814"`}>
      <button className="mt-4 ml-6 py-2 px-6 text-white font-inter ">
        Call Now
      </button>
    </a>
        </div>
      </div>
      <div className="mt-10 md:mt-20">
        <h1 className="text-2xl md:text-4xl font-donegal font-medium">Why Choose Us ?</h1>
        <div className="flex flex-col md:flex-row gap-5 mt-8 md:mt-14">
          <div className="py-6 px-4 font-donegal border-t border-b border-[#276897]">
            <h3 className="md:text-lg uppercase">EXPERTISE</h3>
            <p className="mt-5 text-[#6B6B6B]">
            At Stellar Doors & Hardware, our team comprises industry experts
             with in-depth knowledge of hardware solutions. We leverage this expertise 
             to provide you with superior products and guidance for your projects.
            </p>
          </div>
          <div className="py-6 px-4 font-donegal border-t border-b border-[#276897]">
            <h3 className="md:text-lg uppercase">Client-Centric Approach</h3>
            <p className="mt-5 text-[#6B6B6B]">
             Our client-centric approach is the  foundation of our service,
              tailored to meet your specific requirements. We strive to exceed 
              expectations through personalized attention and
              dedicated support.
            </p>
          </div>
          <div className="py-6 px-4 font-donegal border-t border-b border-[#276897]">
            <h3 className="md:text-lg uppercase">Transparency</h3>
            <p className="mt-5 text-[#6B6B6B]">
            Stellar Doors & Hardware is committed to transparency, ensuring clarity in all our operations and transactions. We believe in open communication and honesty, providing you with confidence and trust in our services.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
