import React from "react"
import ProductCard from "../../components/ProductComponent/ProductCard"
import WoodDoor from "../../images/Door-types/WoodDoor.png"
import AlmuniumFrames from "../../images/Door-types/AlmuniumFrames.png"
import Accessories from "../../images/Door-types/Accesories.png"
import TrafficDoors from "../../images/Door-types/TrafficDoors.png"
import MetalDoor from "../../images/Door-types/MetalDoor.png"
import { HashLink } from "react-router-hash-link"

const Products = () => {
  return (
    <div className="max-container ">
      <h1 className="mt-10 md:mt-20 font-donegal justify-center flex text-2xl md:text-3xl">
        Doors and Hardware Products
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-col-5 gap-5 max-container">
        <ProductCard
          image={MetalDoor}
          header="
           Metal Doors and Frames"
          link="/products/hollow-metal-doors"
        />
        <ProductCard
          image={WoodDoor}
          header="
Wood Doors"
          link="/products/wood-doors"
        />
        <ProductCard
          image={AlmuniumFrames}
          header="

Interior Aluminum Frames"
          link="/products/interior-alumnium-frames"
        />
        <ProductCard
          image={TrafficDoors}
          header="Traffic Doors"
          link="/products/traffic-doors"
        />
        <ProductCard
          image={Accessories}
          header="Hardware and Accesories"
          link="/products/hardware"
        />
      </div>
      <div className="flex justify-center mt-6 md:mt-20">
        <HashLink to="/#getQuote">
          <button className="mt-4 md:ml-6 py-2 px-6 text-white font-inter  ">
            Get Quote
          </button>
        </HashLink>
      </div>
    </div>
  )
}

export default Products
