import React from "react"
import contactImage from "../../images/Home/Contact.png"
import { BiPhoneCall } from "react-icons/bi"
import { IoLocationOutline } from "react-icons/io5"
import { MdMarkEmailUnread } from "react-icons/md"

const ContactUs = () => {
  return (
    <div id="contact" className="relative w-full mt-16 md:mt-20 max-container">
      <img
        className="w-full h-80 md:h-96 object-cover blur-[2px]"
        src={contactImage}
        alt="Contact"
      />
      <div className="absolute top-0 right-0 mt-10 mr-2 md:mr-10 md:mt-10 lg:mr-16 lg:mt-16 p-4 md:p-6 lg:p-10 rounded-md backdrop-blur-md backdrop-brightness-125 bg-[#eeeeeed4] w-full md:w-auto max-w-sm">
        <h2 className="text-2xl md:text-3xl font-donegal font-semibold">
          Contact Us
        </h2>
        <div className="mt-6 md:mt-8">
          <div className="flex items-center gap-2">
            <BiPhoneCall size={30} />
            <p className="text-base md:text-lg">832 576 5814</p>
          </div>
          <div className="flex items-center gap-2 mt-4 md:mt-5">
            <IoLocationOutline size={30} />
            <p className="text-base md:text-lg">
              5858 Cunningham Rd Houston TX 77041
            </p>
          </div>
          <div className="flex items-center gap-2 mt-4 md:mt-5">
            <MdMarkEmailUnread size={30} />
            <p className="text-base md:text-lg">sales@stellardandh.com</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUs
