import React from "react";
import { CiTwitter } from "react-icons/ci";
import { CiLinkedin } from "react-icons/ci";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';


const Footer = () => {
  return (
    <footer className="bg-gray-900 text-gray-400 py-6 text-sm mt-10 md:mt-20 font-inter">
      <div className="container mx-auto px-8">
        <div className="grid grid-cols-4 gap-16">
          <div className="flex flex-col items-start">
            <a className="mb-4" href="/">
              <span className="text-lg font-semibold text-gray-200">Stellar Doors & Hardware</span>
            </a>
            <p className="text-sm leading-relaxed">Commercial Doors and Hardware</p>
          </div>
          <div className="flex flex-col items-start">
            <h3 className="font-semibold text-gray-200 mb-4">Quick Links</h3>
            <nav className="space-y-2 flex flex-col items-start text-sm">
              <Link className="hover:text-gray-300 transition-colors" to="/about">
                About
              </Link>
              <HashLink className="hover:text-gray-300 transition-colors" to="/#getQuote">
                Get a Quote
              </HashLink>
              <HashLink className="hover:text-gray-300 transition-colors" to="/#contact">
                Contact
              </HashLink>
            </nav>
          </div>
          <div className="flex flex-col items-start">
            <Link to="/more">
            <h3 className="font-semibold text-gray-200 mb-4">More</h3></Link>
            <nav className="space-y-2 flex flex-col items-start text-sm">
              <Link className="hover:text-gray-300 transition-colors" to="/more/installation-videos">
                Installation Videos
              </Link>
              <Link className="hover:text-gray-300 transition-colors" to="/more/industry-link">
                Industry Links
              </Link>
              <Link className="hover:text-gray-300 transition-colors" to="/more/privacy-policy">
                Privacy Policy
              </Link>
            </nav>
          </div>
          <div className="flex flex-col items-start">
            <h3 className="font-semibold text-gray-200 mb-4">Follow Us</h3>
            <div className="flex space-x-4 text-sm">
           
              <a className="hover:text-gray-300 transition-colors" href="#">
                <CiTwitter className="h-4 w-4" />
              </a>
              <a className="hover:text-gray-300 transition-colors" href="#">
                <CiLinkedin className="h-4 w-4" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8 border-t border-gray-800 pt-4 text-center">
        <p className="text-sm">© 2024 Stellar Doors & Hardware Inc. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
