import React from "react"
import SidedFrame from "../../images/hollow-metal-frames/SidedFrame.png"
import SidelightFrame from "../../images/hollow-metal-frames/SidelightFrame.png"
import TransformFrame from "../../images/hollow-metal-frames/TransfomFrame.png"
import browwedlite from "../../images/hollow-metal-frames/bor.png"

import { GoDash } from "react-icons/go"

const HollowMetalFrames = (props) => {
  return (
    <div className="max-container mt-10 md:mt-20">
      <h1 className="text-2xl md:text-4xl font-donegal">Hollow Metal Frames</h1>

      {/* <div className="mt-3 flex items-center">
        <GoDash />
        <p className="mx-2 text-[#276897] font-donegal">
          Our comprehensive line of metal doors ranges from cost efficient doors
          to fire rated and lead lined doors.
        </p>
      </div> */}

      <div className="mt-16 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 gap-y-14 ">
        <div className="w-full text-center ">
          <img src={SidedFrame} className="w-[275px] h-[275px] mx-auto" />
          <h3 className="mt-4 font-donegal">Sided Frame</h3>
        </div>
        <div className="w-full text-center ">
          <img src={SidelightFrame} className="w-[275px] h-[275px] mx-auto" />
          <h3 className="mt-4 font-donegal">Sidelight Frame</h3>
        </div>
        <div className="w-full text-center ">
          <img src={TransformFrame} className="w-[275px] h-[275px] mx-auto" />
          <h3 className="mt-4 font-donegal">Transom Frame</h3>
        </div>
        <div className="w-full text-center flex flex-col">
          <img src={browwedlite} className="w-[275px] h-[275px] mx-auto" />
          <h3 className="mt-4 font-donegal">Borrowed Lite</h3>
        </div>
      </div>

      <h2 className="mt-16 text-xl font-donegal">Available Options</h2>
      <section class="grid grid-cols-1 lg:grid-cols-4 gap-x-8 gap-y-4 mt-6">
        <div class="flex flex-col justify-center">
          <div class="flex flex-col h-full shadow justify-between rounded-lg pb-8 p-6 xl:p-8 mt-3 bg-gray-50">
            <div>
              <h4 class=" font-bold text-lg leading-tight">Guage</h4>
              <div class="my-4">
                <p>16, 14 Ga - Various gauge options available.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col justify-center">
          <div class="flex flex-col h-full shadow justify-between rounded-lg pb-8 p-6 xl:p-8 mt-3 bg-gray-50">
            <div>
              <h4 class=" font-bold text-lg leading-tight">Galvanized</h4>
              <div class="my-4">
                <p>
                  Features a protective zinc coating for enhanced corrosion
                  resistance.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col justify-center">
          <div class="flex flex-col h-full shadow justify-between rounded-lg pb-8 p-6 xl:p-8 mt-3 bg-gray-50">
            <div>
              <h4 class=" font-bold text-lg leading-tight">Welded</h4>
              <div class="my-4">
                <p>
               Offer superior strength and rigidity. The welding process creates a robust, one-piece frame ideal for heavy-duty applications.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col justify-center">
          <div class="flex flex-col h-full shadow justify-between rounded-lg pb-8 p-6 xl:p-8 mt-3 bg-gray-50">
            <div>
              <h4 class=" font-bold text-lg leading-tight">
                Custom Hardware Preps
              </h4>
              <div class="my-4">
                <p>
                  Provides tailored preparations for specific hardware
                  requirements.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col justify-center">
          <div class="flex flex-col h-full shadow justify-between rounded-lg pb-8 p-6 xl:p-8 mt-3 bg-gray-50">
            <div>
              <h4 class=" font-bold text-lg leading-tight">
                Custom Hinge Location
              </h4>
              <div class="my-4">
                <p>
                Custom hinge locations offer flexibility in door design. Specifying exact hinge placement accommodates various door sizes and styles for optimal operation.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col justify-center">
          <div class="flex flex-col h-full shadow justify-between rounded-lg pb-8 p-6 xl:p-8 mt-3 bg-gray-50">
            <div>
              <h4 class=" font-bold text-lg leading-tight">Drywall Frame</h4>
              <div class="my-4">
                <p>Drywall frames simplify installation in drywall construction. They offer a secure, stable opening that aligns perfectly with drywall for a clean, professional finish.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col justify-center">
          <div class="flex flex-col h-full shadow justify-between rounded-lg pb-8 p-6 xl:p-8 mt-3 bg-gray-50">
            <div>
              <h4 class=" font-bold text-lg leading-tight">
                Special Reinforcements
              </h4>
              <div class="my-4">
                <p>
                  Offers additional reinforcement options to enhance structural
                  strength. 
                </p>
              </div>
            </div>
          </div>
        </div>
      
      </section>
    </div>
  )
}

export default HollowMetalFrames
