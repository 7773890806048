import React from "react"
import door1 from "../../images/wood-door/door1.jpg"

import laminate1 from "../../images/wood-door/laminate-1.jpg"
import laminate2 from "../../images/wood-door/laminate-2.jpg"
import laminate3 from "../../images/wood-door/laminate-3.jpg"
import laminate4 from "../../images/wood-door/laminate-4.jpg"
import veener1 from "../../images/wood-door/veener1.png"
import veener2 from "../../images/wood-door/veener2.png"
import veener3 from "../../images/wood-door/veener3.png"
import veener4 from "../../images/wood-door/veener4.jpg"

const Hardware = () => {
  return (
    <div className="max-container mt-14">
      <div className="h-[250px] md:h-[400px] w-full relative">
        <img
          className="h-full w-full object-cover rounded-2xl"
          src={door1}
          alt="image"
        />
        <h1 className="absolute  bottom-28 md:bottom-40 md:left-36 text-2xl md:text-4xl leading-normal font-donegal bg-opacity-50 px-2 py-1 rounded">
          Commercial Wood Doors
        </h1>
      </div>
      <div className="mt-10 md:mt-14">
        <h2 className="font-donegal text-xl md:text-2xl">
          Architectural Laminate Doors
        </h2>
        <p className="mt-6 leading-relaxed md:leading-[1.9] text-[#6B6B6B]">
          Our laminate doors offer substantial benefits to both contractors and
          end users. They come pre-finished and ready to install, which
          simplifies the setup process significantly. These doors provide a
          robust alternative to traditional wood veneer, ensuring long-lasting
          durability. Moreover, they feature an extensive palette of color
          options, allowing for seamless integration into any interior design
          scheme. This versatility makes them an ideal solution across a wide
          range of applications, catering to diverse aesthetic and functional
          needs.
        </p>
        <div className="mt-10 md:mt-16 flex flex-col md:flex-row items-center gap-6 justify-evenly md:h-[350px]">
          <img
            src={laminate1}
            className="h-full w-[90%] md:w-[270px] drop-shadow-2xl rounded-lg cursor-pointer hover:scale-105 transition ease-in-out duration-300"
          />
          <img
            src={laminate2}
            className="h-full w-[90%] md:w-[270px] md:object-cover drop-shadow-2xl  rounded-lg cursor-pointer hover:scale-105 transition ease-in-out duration-300"
          />
          <img
            src={laminate3}
            className="h-full w-[90%] md:w-[270px] md:object-cover drop-shadow-2xl  rounded-lg cursor-pointer hover:scale-105 transition ease-in-out duration-300"
          />
          <img
            src={laminate4}
            className="h-full w-[90%]  md:w-[270px] drop-shadow-2xl  rounded-lg cursor-pointer hover:scale-105 transition ease-in-out duration-300"
          />
        </div>
      </div>

      <div className="mt-14 md:mt-20">
        <h2 className="font-donegal text-xl md:text-2xl">Wood Veneer Door</h2>
        <p className="mt-6 leading-relaxed md:leading-[1.9] text-[#6B6B6B]">
          Our wood veneer doors offer versatile finishing options, allowing for
          a wide range of paint and stain choices to achieve diverse finishes.
          Available in various wood species such as birch, maple, mahogany, oak,
          cherry, walnut, and more, these doors cater to different aesthetic
          preferences. They are also customizable with different core options
          and can be fire-rated for up to 90 minutes, ensuring safety and
          compliance with building regulations.
        </p>
        <div className="mt-10 md:mt-16 flex flex-col md:flex-row items-center gap-6 justify-evenly md:h-[350px]">
          <img
            src={veener1}
            className="h-full w-[90%]  md:w-[270px] drop-shadow-2xl  rounded-lg cursor-pointer hover:scale-105 transition ease-in-out duration-300"
          />
          <img
            src={veener2}
            className="h-full w-[90%]  md:w-[270px] drop-shadow-2xl  rounded-lg cursor-pointer hover:scale-105 transition ease-in-out duration-300"
          />
          <img
            src={veener3}
            className="h-full w-[90%]  md:w-[270px] drop-shadow-2xl rounded-lg cursor-pointer hover:scale-105 transition ease-in-out duration-300"
          />
          <img
            src={veener4}
            className="h-full w-[90%]  md:w-[270px] drop-shadow-2xl  rounded-lg cursor-pointer hover:scale-105 transition ease-in-out duration-300"
          />
        </div>
      </div>
    </div>
  )
}

export default Hardware
