import React from "react";

const Map = () => {
  return (
    <iframe
      className="w-full h-72 mt-20"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3460.3818636384367!2d-95.58327222466279!3d29.853259275020413!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640e119687bc9a5%3A0x4897018d8193dc56!2sStellar%20Architectural%20Woodwork!5e0!3m2!1sen!2s!4v1719643879754!5m2!1sen!2s"
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    ></iframe>
  );
};
export default Map;
