import React, { useState } from "react"
import { Slide } from "react-slideshow-image"
import YouTube from "react-youtube"
import "react-slideshow-image/dist/styles.css"

const InstallationVideos = () => {
  const videos = [
    {
      id: 0,
      title: "How To Fix a Sagging Door that's Rubbing or Won't Close!!!",
      description: "True methods to fix a door that is jam",
      videoId: "ZcDj6g2UODw",
      thumbnail: "https://img.youtube.com/vi/ZcDj6g2UODw/mqdefault.jpg",
    },
    {
      id: 1,
      title: "How To Measure Door Size For Replacement",
      description: "Measure for Replacement Steel Doors",
      videoId: "8FmzpUnrlww",
      thumbnail: "https://img.youtube.com/vi/8FmzpUnrlww/mqdefault.jpg",
    },
    {
      id: 2,
      title: "How to Install a Commercial Steel Door",
      description: "Install a Commercial Steel Door",
      videoId: "Lq9q-5yQdzg",
      thumbnail: "https://img.youtube.com/vi/Lq9q-5yQdzg/mqdefault.jpg",
    },
    {
      id: 3,
      title: "How to Set a Steel Door Frame in Block Wall",
      description: "Install Steel Door Frame",
      videoId: "doTYk3YXIVU",
      thumbnail: "https://img.youtube.com/vi/doTYk3YXIVU/mqdefault.jpg",
    },
    {
      id: 4,
      title: "How to Install Slip-On Drywall Frames",
      description: "Install Slip-On Drywall Frames",
      videoId: "hB41CSou7-o",
      thumbnail: "https://img.youtube.com/vi/hB41CSou7-o/mqdefault.jpg",
    },
    {
      id: 5,
      title: "How to Install Punch and Dimple Frames in Masonry Construction",
      description: "Install Punch and Dimple Frames in Masonry Construction",
      videoId: "VYRyLN3H1T4",
      thumbnail: "https://img.youtube.com/vi/VYRyLN3H1T4/mqdefault.jpg",
    },
  ]

  const [selectedVideo, setSelectedVideo] = useState(videos[0])
  const [player, setPlayer] = useState(null)
  const [isPlaying, setIsPlaying] = useState(false)

  const opts = {
    height: "400",
    width: "100%",
    playerVars: {
      autoplay: 0,
      controls: 1,
      rel: 0,
    },
  }

  const onReady = (event) => {
    setPlayer(event.target)
  }

  const playVideo = () => {
    if (player) {
      player.playVideo()
      setIsPlaying(true)
    }
  }

  const responsiveSettings = [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 0,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ]

  return (
    <div className="max-container mt-14">
      <h1 className="text-2xl md:text-4xl justify-center flex font-donegal ">
        Installation Videos
      </h1>
      <div className="flex flex-col justify-center items-center mb-8 mt-8 md:mt-14 w-full relative">
        <div className="w-full md:w-1/2">
          <YouTube
            className={`flex justify-center w-full ${
              !isPlaying ? "blur-sm" : ""
            }`}
            videoId={selectedVideo.videoId}
            opts={opts}
            onReady={onReady}
          />
        </div>
        {!isPlaying && (
          <button
            onClick={playVideo}
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-blue-500 hover:bg-blue-800 hover:text-white hover:border-none text-white font-bold py-2 px-4 rounded"
          >
            Play Video
          </button>
        )}
        <div className="mt-4 text-center">
          <h2 className="md:text-xl font-bold">{selectedVideo.title}</h2>
          <p className="text-sm md:text-base text-gray-600">
            {selectedVideo.description}
          </p>
        </div>
      </div>
      <div className="mt-12">
        <Slide
          slidesToShow={4}
          indicators={true}
          infinite={true}
          autoplay={true}
          responsive={responsiveSettings}
        >
          {videos.map((video) => (
            <img
              onClick={() => {
                setIsPlaying(false)
                setSelectedVideo(video)
              }}
              key={video.id}
              className="h-full rounded-2xl w-full md:w-[90%] object-cover cursor-pointer"
              src={video.thumbnail}
              alt={video.title}
            />
          ))}
        </Slide>
      </div>
    </div>
  )
}

export default InstallationVideos
