import React from "react"
import DoorInstallation from "../../images/more/door_installation.png"
import IndustryLink from "../../images/more/industry.png"
import { HashLink } from "react-router-hash-link"

const index = () => {
  return (
    <div className="max-container mt-10 md:mt-20">
      <h1 className="text-2xl md:text-4xl justify-center flex font-donegal ">
        Resources
      </h1>
      <div className="flex flex-col md:flex-row mt-10 md:mt-16 justify-center items-center w-full md:space-x-8">
        <HashLink
          className="flex flex-col items-center justify-center md:w-[45%]"
          to={"/more/installation-videos"}
        >
          <img
            src={DoorInstallation}
            className="shadow-md rounded-lg cursor-pointer w-[500px]"
          />
          <h3 className="mt-6 text-lg md:text-xl font-donegal text-center">
            Installation Videos
          </h3>
        </HashLink>
        <HashLink
          className="flex flex-col mt-8 md:mt-0 items-center justify-center md:w-[45%]"
          to={"/more/industry-link"}
        >
          <img
            src={IndustryLink}
            className="shadow-md rounded-lg cursor-pointer w-[540px]"
          />
          <h3 className="mt-6 text-lg md:text-xl font-donegal text-center">
            Industry Links
          </h3>
        </HashLink>
      </div>
    </div>
  )
}

export default index
