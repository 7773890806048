import React from "react"
import Hero from "../components/AboutComponent/Hero"
import Review from "../components/AboutComponent/Review"
import profile1 from "../images/About/profile1.jpg"
import profile2 from "../images/About/profile2.jpg"
import profile3 from "../images/About/profile3.jpg"
import Map from "../components/Map"

const About = () => {
  return (
    <div id="about" className="max-container">
      <Hero />
      <h1 className="mt-10 md:mt-20 text-2xl md:text-4xl font-donegal font-medium">
        Reviews
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        <Review
          image={profile1}
          rating={5}
          name="Emily"
          review=" Stellar Doors & Hardware provided outstanding service from start to finish.
           They have a great variety of doors and frames, and the staff was very knowledgeable
            and friendly. I appreciated their attention to detail and the quality of their products."
        />

        <Review
          image={profile2}
          rating={4.5}
          name="Andrew"
          review="I had a fantastic experience with Stellar Doors & Hardware. Their selection 
          of doors is incredible, and the team was extremely helpful in guiding me through the 
          options. The installation process was seamless, and the final result exceeded my 
          expectations."
        />

        <Review
          image={profile3}
          rating={5}
          name="Noah"
          review="Stellar Doors & Hardware is simply the best! They helped me choose the perfect doors for my new office building. The quality of the doors is exceptional, and their customer service is top-notch. I highly recommend them for any door and hardware needs."
        />
      </div>
      <Map />
    </div>
  )
}

export default About
