import React from "react"
import roundwindow12 from "../../images/Traffic-Doors/12-round-window.png"
import roundwindow15 from "../../images/Traffic-Doors/15-round-window.png"
import window16 from "../../images/Traffic-Doors/16-window.png"
import window9 from "../../images/Traffic-Doors/9-window.png"

const TrafficDoor = (props) => {
  return (
    <div  className="max-container mt-10 md:mt-20">
      <h1 className="text-2xl md:text-4xl font-donegal">Traffic Doors</h1>

      {/* <div className="mt-3 flex items-center">
        <GoDash />
        <p className="mx-2 text-[#276897] font-donegal">
          Our comprehensive line of metal doors ranges from cost efficient doors
          to fire rated and lead lined doors.
        </p>
      </div> */}

      <div className="mt-16 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 gap-y-14 ">
        <div className="w-full text-center ">
          <img src={roundwindow12} className="w-[30%] mx-auto" />
          <h3 className="mt-4 font-donegal">12" Round Window</h3>
        </div>
        <div className="w-full text-center ">
          <img src={roundwindow15} className="w-[30%] mx-auto" />
          <h3 className="mt-4 font-donegal">15" Round Window</h3>
        </div>
        <div className="w-full text-center ">
          <img src={window9} className="w-[30%] mx-auto" />
          <h3 className="mt-4 font-donegal">9" X 30" Window</h3>
        </div>
        <div className="w-full text-center ">
          <img src={window16} className="w-[30%] mx-auto" />
          <h3 className="mt-4 font-donegal">16" x 16" Window</h3>
        </div>
        
      </div>

      <h2 className="mt-16 text-xl font-donegal">Available Options</h2>
      <section class="grid grid-cols-1 lg:grid-cols-4 gap-x-8 gap-y-4 mt-6">
        <div class="flex flex-col justify-center">
          <div class="flex flex-col h-full shadow justify-between rounded-lg pb-8 p-6 xl:p-8 mt-3 bg-gray-50">
            <div>
              <h4 class=" font-bold text-lg leading-tight">12" Round Window</h4>
              <div class="my-4">
                <p>The 12" round window offers moderate visibility and a sleek, modern look. Ideal for commercial kitchens and industrial areas.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col justify-center">
          <div class="flex flex-col h-full shadow justify-between rounded-lg pb-8 p-6 xl:p-8 mt-3 bg-gray-50">
            <div>
              <h4 class=" font-bold text-lg leading-tight">15" Round Window</h4>
              <div class="my-4">
                <p>
                The 15" round window provides greater visibility and a more substantial appearance. Suitable for commercial settings.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col justify-center">
          <div class="flex flex-col h-full shadow justify-between rounded-lg pb-8 p-6 xl:p-8 mt-3 bg-gray-50">
            <div>
              <h4 class=" font-bold text-lg leading-tight">
              9" x 30" Window
              </h4>
              <div class="my-4">
                <p>
                The 9" x 30" rectangular window offers high visibility with a tall, narrow viewing area. Ideal for medical facilities and offices.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col justify-center">
          <div class="flex flex-col h-full shadow justify-between rounded-lg pb-8 p-6 xl:p-8 mt-3 bg-gray-50">
            <div>
              <h4 class=" font-bold text-lg leading-tight">
              16" x 16" Window
              </h4>
              <div class="my-4">
                <p>
                The 16" x 16" square window provides a balanced view with equal width and height. Versatile and suitable for various settings.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col justify-center">
          <div class="flex flex-col h-full shadow justify-between rounded-lg pb-8 p-6 xl:p-8 mt-3 bg-gray-50">
            <div>
              <h4 class=" font-bold text-lg leading-tight">
              Customization
              </h4>
              <div class="my-4">
                <p>
                Traffic doors can be customized to fit specific needs, including window size, shape, and placement. 
                </p>
              </div>
            </div>
          </div>
        </div>
      
      </section>
      
    </div>
  )
}

export default TrafficDoor
