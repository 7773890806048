import React, { useState } from "react"
import {
  AiOutlineClose,
  AiOutlineMenu,
  AiOutlineDown,
  AiOutlineUp,
} from "react-icons/ai"
import logo from "../images/logo.png"
import { Link } from "react-router-dom"
import { HashLink } from "react-router-hash-link"

const Navbar = () => {
  const [nav, setNav] = useState(false)
  const [mobileDropdown, setMobileDropdown] = useState(null)

  const handleNav = () => {
    setNav(!nav)
  }

  const toggleMobileDropdown = (id) => {
    setMobileDropdown((prev) => (prev === id ? null : id))
  }

  const productItems = [
    { id: 1, text: "Hollow Metal Door", link: "/products/hollow-metal-doors" },
    {
      id: 2,
      text: "Hollow Metal Frames",
      link: "/products/hollow-metal-frames",
    },
    { id: 3, text: "Wood Door", link: "/products/wood-doors" },
    {
      id: 4,
      text: "Interior Aluminum Frames",
      link: "/products/interior-alumnium-frames",
    },
    { id: 5, text: "Traffic Doors", link: "/products/traffic-doors" },
    { id: 6, text: "Hardware", link: "/products/hardware" },
  ]

  const moreItems = [
    { id: 2, text: "Installation Videos", link: "/more/installation-videos" },
    { id: 3, text: "Industry Links", link: "/more/industry-link" },
    { id: 4, text: "Privacy Policy", link: "/more/privacy-policy" },
  ]

  const navItems = [
    { id: 1, text: "Home", link: "/" },
    { id: 2, text: "About", link: "/about" },
    {
      id: 3,
      text: "Products",
      link: "/products",
      dropdown: true,
      items: productItems,
    },
    { id: 4, text: "Get a Quote", link: "/#getQuote" },
    { id: 5, text: "Contact Us", link: "/#contact" },
    { id: 6, text: "More", link: "/more", dropdown: true, items: moreItems },
  ]

  return (
    <nav className="flex justify-between items-center max-container py-2 border-b-2 border-[#cdcdd8]">
      {/* Logo */}
      <Link to="/">
        <img src={logo} alt="Logo" className="w-24 md:h-28 md:w-32" />
      </Link>

      {/* Desktop Navigation */}
      <ul className="hidden md:flex items-center">
        {navItems.map((item) =>
          item.dropdown ? (
            <div key={item.id} className="relative group">
              <HashLink
                to={item.link}
                className="p-4 rounded-xl m-2 cursor-pointer hover:bg-[#DDDDDD] duration-300 text-black"
              >
                {item.text}
              </HashLink>
              <ul className="z-20 absolute bg-white shadow-lg rounded-md mt-5 w-max hidden group-hover:block">
                {item.items.map((subItem) => (
                  <HashLink
                    to={subItem.link}
                    key={subItem.id}
                    className="block p-4 text-sm hover:bg-gray-200 rounded-md cursor-pointer"
                  >
                    {subItem.text}
                  </HashLink>
                ))}
              </ul>
            </div>
          ) : (
            <HashLink
              to={item.link}
              key={item.id}
              className="p-4 hover:bg-[#DDDDDD] rounded-xl m-2 cursor-pointer duration-300 hover:text-black text-black"
            >
              {item.text}
            </HashLink>
          )
        )}
      </ul>

      {/* Mobile Menu Icon */}
      <div onClick={handleNav} className="md:hidden z-50">
        {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>

      {/* Mobile Navigation Dropdown */}
      <ul
        className={`md:hidden fixed z-40 bg-white top-0 left-0 w-full h-full flex flex-col items-center justify-center transition-transform transform ${
          nav ? "translate-x-0" : "translate-x-full"
        }`}
      >
        {/* Mobile Logo */}
        <img src={logo} alt="Logo" className="h-14 mb-6" />
        {/* Mobile Navigation Items */}
        {navItems.map((item) =>
          item.dropdown ? (
            <div key={item.id} className="w-full text-center">
              <span
                className="p-4 hover:bg-gray-200 rounded-md cursor-pointer flex justify-center items-center"
                onClick={() => toggleMobileDropdown(item.id)}
              >
                <HashLink className="mr-2" to={item.link} onClick={() => setNav(false)}>
                  <span className="mx-auto">{item.text}</span>
                </HashLink>
                {mobileDropdown === item.id ? (
                  <AiOutlineUp />
                ) : (
                  <AiOutlineDown />
                )}
              </span>
              {mobileDropdown === item.id && (
                <ul className="w-full mt-2">
                  {item.items.map((subItem) => (
                    <HashLink
                      to={subItem.link}
                      key={subItem.id}
                      className="block p-2 text-sm hover:bg-gray-200 cursor-pointer border-b-2 mx-auto"
                      onClick={handleNav}
                    >
                      {subItem.text}
                    </HashLink>
                  ))}
                </ul>
              )}
            </div>
          ) : (
            <HashLink
              to={item.link}
              key={item.id}
              className="p-4 hover:bg-gray-200 rounded-md cursor-pointer w-full text-center"
              onClick={handleNav}
            >
              {item.text}
            </HashLink>
          )
        )}
      </ul>
    </nav>
  )
}

export default Navbar
