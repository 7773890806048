import React from "react"
import CecoDoor from "../../images/industry-link/CecoDoor.png"
import CurriesLogo from "../../images/industry-link/CurriesLogo.png"
import PremierLogo from "../../images/industry-link/PremierLogo.png"
import Republic from "../../images/industry-link/Republic.png"
import Steelcraft from "../../images/industry-link/Steelcraft.png"
import MeskerDoor from "../../images/industry-link/meskerdoor-logo-main.png"

import Strek from "../../images/industry-link/Strek.png"
import oshkosh from "../../images/industry-link/oshkosh.png"
import Texas from "../../images/industry-link/tex.png"

import stainless from "../../images/industry-link/speciality/stainless.png"
import Frameworks from "../../images/industry-link/speciality/Frameworks.png"
import cline from "../../images/industry-link/speciality/cline-logo.png"
import corrim from "../../images/industry-link/speciality/corrim-logo.png"
import eliason from "../../images/industry-link/speciality/eliason-logo.png"
import special from "../../images/industry-link/speciality/special.png"

import Adams from "../../images/industry-link/hardware/Adams.png"
import McKinney from "../../images/industry-link/hardware/McKinney.png"
import Norton from "../../images/industry-link/hardware/Norton.png"
import Pemko from "../../images/industry-link/hardware/Pemko.png"
import Precision from "../../images/industry-link/hardware/Precision.png"
import Rixson from "../../images/industry-link/hardware/Rixson.png"
import Rockwood from "../../images/industry-link/hardware/Rockwood.png"
import Trimco from "../../images/industry-link/hardware/Trimco.png"
import lcn from "../../images/industry-link/hardware/lcn.png"
import hager from "../../images/industry-link/hardware/hager.png"
import best from "../../images/industry-link/hardware/best.png"
import design from "../../images/industry-link/hardware/design.png"
import falcon from "../../images/industry-link/hardware/falcon.png"
import glynn from "../../images/industry-link/hardware/glynn.png"
import ives from "../../images/industry-link/hardware/ives.png"
import schlage from "../../images/industry-link/hardware/schlage.png"
import vonduprin from "../../images/industry-link/hardware/vonduprin.png"
import kaba from "../../images/industry-link/hardware/kaba.png"
import stanley from "../../images/industry-link/hardware/stanley.png"
import zero from "../../images/industry-link/hardware/zero.png"

import aia from "../../images/industry-link/others/aia.png"
import ansi from "../../images/industry-link/others/ansi.png"
import bhma from "../../images/industry-link/others/bhma.png"
import florida from "../../images/industry-link/others/florida.png"
import dhi from "../../images/industry-link/others/dhi.png"
import naam from "../../images/industry-link/others/naam.png"
import steel from "../../images/industry-link/others/steel.png"
import ulListed from "../../images/industry-link/others/ul.png"
import whi from "../../images/industry-link/others/whi.png"
import astm from "../../images/industry-link/others/astm.png"

import { HashLink } from "react-router-hash-link"

const IndustryLinks = () => {
  return (
    <div className="max-container mt-10 md:mt-20">
      <section className="mt-14 md:mt-24">
        <h1 className="text-xl md:text-3xl font-donegal font-semibold text-blue-950">
          Hollow Metal Manufacturers
        </h1>
        <div className="mt-10 flex items-center justify-center md:justify-start">
          <div className=" grid grid-cols-2 lg:grid-cols-6 gap-10 items-center justify-center">
            <HashLink target="_blank" rel="noreferrer" to="https://www.cecodoor.com/en">
              <div className="border border-gray-300 p-2 flex justify-center items-center w-[150px] h-[150px] md:h-[200px] md:w-[200px]">
                <img
                  src={CecoDoor}
                  alt="CecoDoor"
                  className="rounded-lg cursor-pointer max-h-full max-w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
            </HashLink>
            <HashLink target="_blank" rel="noreferrer" to="https://www.curries.com/en">
              <div className="border border-gray-300 p-2 flex justify-center items-center w-[150px] h-[150px] md:h-[200px] md:w-[200px] rounded-md shadow-m">
                <img
                  src={CurriesLogo}
                  alt="CurriesLogo"
                  className="cursor-pointer max-h-full max-w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
            </HashLink>
            <HashLink target="_blank" rel="noreferrer" to="https://www.trustpremier.com/ ">
              <div className="border border-gray-300 p-2 flex justify-center items-center w-[150px] h-[150px] md:h-[200px] md:w-[200px]  rounded-md shadow-m">
                <img
                  src={PremierLogo}
                  alt="PremierLogo"
                  className="cursor-pointer max-h-full max-w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
            </HashLink>
            <HashLink target="_blank" rel="noreferrer" to="https://www.republicdoor.com/en/index.html">
              <div className="border border-gray-300 p-2 flex justify-center items-center w-[150px] h-[150px] md:h-[200px] md:w-[200px] rounded-md shadow-m">
                <img
                  src={Republic}
                  alt="Republic"
                  className="cursor-pointer max-h-full max-w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
            </HashLink>
            <HashLink target="_blank" rel="noreferrer" to="https://www.steelcraft.com/en/index.html">
              <div className="border border-gray-300 p-2 flex justify-center items-center w-[150px] h-[150px] md:h-[200px] md:w-[200px]  rounded-md shadow-m">
                <img
                  src={Steelcraft}
                  alt="Steelcraft"
                  className="cursor-pointer max-h-full max-w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
            </HashLink>
            <HashLink target="_blank" rel="noreferrer" to="https://meskerdoor.com/">
              <div className="border border-gray-300 p-2 flex justify-center items-center w-[150px] h-[150px] md:h-[200px] md:w-[200px]  rounded-md shadow-m">
                <img
                  src={MeskerDoor}
                  alt="MeskerDoor"
                  className="cursor-pointer max-h-full max-w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
            </HashLink>
          </div>
        </div>
      </section>

      <section className="mt-14 md:mt-24">
        <h1 className="text-xl md:text-3xl font-donegal font-semibold text-blue-950">
          Wood Door Manufacturers
        </h1>
        <div className="mt-10 flex justify-center items-center md:justify-start">
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-10">
            <HashLink  target="_blank" rel="noreferrer" to="http://strekodoors.com/">
              <div className="border border-gray-300 p-2 flex justify-center items-center  w-[150px] h-[150px] md:h-[200px] md:w-[200px]  rounded-md shadow-md">
                <img
                  src={Strek}
                  alt="Strek"
                  className="rounded-lg cursor-pointer max-h-full max-w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
            </HashLink>
            <HashLink target="_blank" rel="noreferrer" to="https://oshkoshdoor.com/">
              <div className="border border-gray-300 p-2 flex justify-center items-center  w-[150px] h-[150px] md:h-[200px] md:w-[200px]  rounded-md shadow-md">
                <img
                  src={oshkosh}
                  alt="oshkosh"
                  className="cursor-pointer max-h-full max-w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
            </HashLink>
          
          </div>
        </div>
      </section>

      <section className="mt-14 md:mt-24">
        <h1 className="text-xl md:text-3xl font-donegal font-semibold text-blue-950">
          Specialty Door Manufacturers
        </h1>
        <div className="mt-10 flex justify-center md:justify-start items-center">
          <div className="grid grid-cols-2 lg:grid-cols-6 gap-10">
            <HashLink target="_blank" rel="noreferrer" to="https://frameworks.com/">
              <div className="border border-gray-300 p-2 flex justify-center items-center  w-[150px] h-[150px] md:h-[200px] md:w-[200px]  rounded-md shadow-md">
                <img
                  src={Frameworks}
                  alt="Frameworks"
                  className="rounded-lg cursor-pointer max-h-full max-w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
            </HashLink>
            <HashLink target="_blank" rel="noreferrer" to="http://www.clinedoors.com/">
              <div className="border border-gray-300 p-2 flex justify-center items-center  w-[150px] h-[150px] md:h-[200px] md:w-[200px]  rounded-md shadow-md">
                <img
                  src={cline}
                  alt="cline"
                  className="cursor-pointer max-h-full max-w-full w-[150px] hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
            </HashLink>
            <HashLink target="_blank" rel="noreferrer" to="https://corrim.com/">
              <div className="border border-gray-300 p-2 flex justify-center items-center  w-[150px] h-[150px] md:h-[200px] md:w-[200px]  rounded-md shadow-md">
                <img
                  src={corrim}
                  alt="corrim"
                  className="cursor-pointer max-h-full max-w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
            </HashLink>
            <HashLink  target="_blank" rel="noreferrer" to="https://www.eliasoncorp.com/">
              <div className="border border-gray-300 p-2 flex justify-center items-center  w-[150px] h-[150px] md:h-[200px] md:w-[200px]  rounded-md shadow-md">
                <img
                  src={eliason}
                  alt="eliason"
                  className="cursor-pointer max-h-full max-w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
            </HashLink>
            <HashLink target="_blank" rel="noreferrer" to="https://stainlessdoors.com/">
              <div className="border border-gray-300 p-2 flex justify-center items-center  w-[150px] h-[150px] md:h-[200px] md:w-[200px]  rounded-md shadow-md">
                <img
                  src={stainless}
                  alt="stainless"
                  className="cursor-pointer max-h-full max-w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
            </HashLink>
            <HashLink target="_blank" rel="noreferrer" to="https://special-lite.com/">
              <div className="border border-gray-300 p-2 flex justify-center items-center  w-[150px] h-[150px] md:h-[200px] md:w-[200px]  rounded-md shadow-md">
                <img
                  src={special}
                  alt="special"
                  className="cursor-pointer max-h-full max-w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
            </HashLink>
          </div>
        </div>
      </section>

      <section className="mt-14 md:mt-24">
        <h1 className="text-xl md:text-3xl font-donegal font-semibold text-blue-950">
          Hardware Manufacturers
        </h1>
        <div className="mt-10 flex justify-center md:justify-start items-center">
          <div className="grid grid-cols-2 lg:grid-cols-6 gap-10">
            <HashLink target="_blank" rel="noreferrer" to="https://www.assaabloylibrary.us/adamsrite/Adams-Rite-May-20-2024-Pricelist/Cover/">
              <div className="border border-gray-300 p-2 flex justify-center items-center  w-[150px] h-[150px] md:h-[200px] md:w-[200px]  rounded-md shadow-md">
                <img
                  src={Adams}
                  alt="Adams"
                  className="rounded-lg cursor-pointer max-h-full max-w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
            </HashLink>
            <HashLink target="_blank" rel="noreferrer" to="https://www.mckinneyhinge.com/en">
              <div className="border border-gray-300 p-2 flex justify-center items-center  w-[150px] h-[150px] md:h-[200px] md:w-[200px]  rounded-md shadow-md">
                <img
                  src={McKinney}
                  alt="McKinney"
                  className="cursor-pointer max-h-full max-w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
            </HashLink>
            <HashLink  target="_blank" rel="noreferrer" to="https://www.nortonrixson.com/en/resources/resource-library">
              <div className="border border-gray-300 p-2 flex justify-center items-center  w-[150px] h-[150px] md:h-[200px] md:w-[200px]  rounded-md shadow-md">
                <img
                  src={Norton}
                  alt="Norton"
                  className="cursor-pointer max-h-full max-w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
            </HashLink>
            <HashLink target="_blank" rel="noreferrer" to="https://www.pemko.com/en">
              <div className="border border-gray-300 p-2 flex justify-center items-center  w-[150px] h-[150px] md:h-[200px] md:w-[200px]  rounded-md shadow-md">
                <img
                  src={Pemko}
                  alt="Pemko"
                  className="cursor-pointer max-h-full max-w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
            </HashLink>
            <HashLink target="_blank" rel="noreferrer" to="https://www.lockmasters.com/phi-precision">
              <div className="border border-gray-300 p-2 flex justify-center items-center  w-[150px] h-[150px] md:h-[200px] md:w-[200px]  rounded-md shadow-md">
                <img
                  src={Precision}
                  alt="Precision"
                  className="cursor-pointer max-h-full max-w-full w-[150px] hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
            </HashLink>
            <HashLink target="_blank" rel="noreferrer" to="https://www.nortonrixson.com/en/resources/resource-library?size=n_18_n&filters%5B0%5D%5Bfield%5D=discontinued&filters%5B0%5D%5Bvalues%5D%5B0%5D=No&filters%5B0%5D%5Btype%5D=all&sort%5B0%5D%5Bfield%5D=updated_at&sort%5B0%5D%5Bdirection%5D=desc">
              <div className="border border-gray-300 p-2 flex justify-center items-center  w-[150px] h-[150px] md:h-[200px] md:w-[200px]  rounded-md shadow-md">
                <img
                  src={Rixson}
                  alt="Rixson"
                  className="cursor-pointer max-h-full max-w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
            </HashLink>
            <HashLink target="_blank" rel="noreferrer" to="https://www.rockwoodmfg.com/en">
              <div className="border border-gray-300 p-2 flex justify-center items-center  w-[150px] h-[150px] md:h-[200px] md:w-[200px]  rounded-md shadow-md">
                <img
                  src={Rockwood}
                  alt="Rockwood"
                  className="cursor-pointer max-h-full max-w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
            </HashLink>
            <HashLink target="_blank" rel="noreferrer" to="https://trimcohardware.com/products/">
              <div className="border border-gray-300 p-2 flex justify-center items-center  w-[150px] h-[150px] md:h-[200px] md:w-[200px]  rounded-md shadow-md">
                <img
                  src={Trimco}
                  alt="Trimco"
                  className="cursor-pointer max-h-full max-w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
            </HashLink>
            <HashLink target="_blank" rel="noreferrer" to="https://us.allegion.com/en/products/brands/lcn.html">
              <div className="border border-gray-300 p-2 flex justify-center items-center  w-[150px] h-[150px] md:h-[200px] md:w-[200px]  rounded-md shadow-md">
                <img
                  src={lcn}
                  alt="LCN"
                  className="cursor-pointer max-h-full max-w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
            </HashLink>
            <HashLink target="_blank" rel="noreferrer" to="https://www.hagerco.com/Product-Categories.aspx">
              <div className="border border-gray-300 p-2 flex justify-center items-center  w-[150px] h-[150px] md:h-[200px] md:w-[200px]  rounded-md shadow-md">
                <img
                  src={hager}
                  alt="Hager"
                  className="cursor-pointer max-h-full max-w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
            </HashLink>
            <HashLink target="_blank" rel="noreferrer" to="https://www.bestaccess.com/products/">
              <div className="border border-gray-300 p-2 flex justify-center items-center  w-[150px] h-[150px] md:h-[200px] md:w-[200px]  rounded-md shadow-md">
                <img
                  src={best}
                  alt="Best"
                  className="cursor-pointer max-h-full max-w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
            </HashLink>
            <HashLink target="_blank" rel="noreferrer" to="https://designhardware.net/">
              <div className="border border-gray-300 p-2 flex justify-center items-center  w-[150px] h-[150px] md:h-[200px] md:w-[200px]  rounded-md shadow-md">
                <img
                  src={design}
                  alt="Design"
                  className="cursor-pointer max-h-full max-w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
            </HashLink>
            <HashLink target="_blank" rel="noreferrer" to="/https://us.allegion.com/en/products/brands/falcon.html">
              <div className="border border-gray-300 p-2 flex justify-center items-center  w-[150px] h-[150px] md:h-[200px] md:w-[200px]  rounded-md shadow-md">
                <img
                  src={falcon}
                  alt="Falcon"
                  className="cursor-pointer max-h-full max-w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
            </HashLink>
            <HashLink target="_blank" rel="noreferrer" to="https://us.allegion.com/en/products/brands/glynn-johnson.html">
              <div className="border border-gray-300 p-2 flex justify-center items-center  w-[150px] h-[150px] md:h-[200px] md:w-[200px]  rounded-md shadow-md">
                <img
                  src={glynn}
                  alt="Glynn"
                  className="cursor-pointer max-h-full max-w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
            </HashLink>
            <HashLink target="_blank" rel="noreferrer" to="https://us.allegion.com/en/products/brands/ives.html">
              <div className="border border-gray-300 p-2 flex justify-center items-center  w-[150px] h-[150px] md:h-[200px] md:w-[200px]  rounded-md shadow-md">
                <img
                  src={ives}
                  alt="Ives"
                  className="cursor-pointer max-h-full max-w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
            </HashLink>
            <HashLink target="_blank" rel="noreferrer" to="https://us.allegion.com/en/products/brands/schlage.html#top">
              <div className="border border-gray-300 p-2 flex justify-center items-center  w-[150px] h-[150px] md:h-[200px] md:w-[200px]  rounded-md shadow-md">
                <img
                  src={schlage}
                  alt="Schlage"
                  className="cursor-pointer max-h-full max-w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
            </HashLink>
            <HashLink target="_blank" rel="noreferrer" to="https://us.allegion.com/en/products/brands/von-duprin.html">
              <div className="border border-gray-300 p-2 flex justify-center items-center  w-[150px] h-[150px] md:h-[200px] md:w-[200px]  rounded-md shadow-md">
                <img
                  src={vonduprin}
                  alt="Von Duprin"
                  className="cursor-pointer max-h-full max-w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
            </HashLink>
            <HashLink target="_blank" rel="noreferrer" to="https://www.dormakaba.com/us-en/offering/products/door-hardware">
              <div className="border border-gray-300 p-2 flex justify-center items-center  w-[150px] h-[150px] md:h-[200px] md:w-[200px]  rounded-md shadow-md">
                <img
                  src={kaba}
                  alt="Kaba"
                  className="cursor-pointer max-h-full max-w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
            </HashLink>
            <HashLink target="_blank" rel="noreferrer" to="https://www.stanleyhinges.com/">
              <div className="border border-gray-300 p-2 flex justify-center items-center  w-[150px] h-[150px] md:h-[200px] md:w-[200px]  rounded-md shadow-md">
                <img
                  src={stanley}
                  alt="Stanley"
                  className="cursor-pointer max-h-full max-w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
            </HashLink>
            <HashLink target="_blank" rel="noreferrer" to="https://us.allegion.com/en/products/brands/zero-international.html">
              <div className="border border-gray-300 p-2 flex justify-center items-center  w-[150px] h-[150px] md:h-[200px] md:w-[200px]  rounded-md shadow-md">
                <img
                  src={zero}
                  alt="Zero"
                  className="cursor-pointer max-h-full max-w-full w-[150px] hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
            </HashLink>
          </div>
        </div>
      </section>

      <section className="mt-14 md:mt-24">
        <h1 className="text-xl md:text-3xl font-donegal font-semibold text-blue-950">
          Others
        </h1>
        <div className="mt-10 flex justify-center md:justify-start items-center">
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-10">
            <HashLink target="_blank" rel="noreferrer"
              className="flex flex-col items-center justify-center"
              to="https://www.aia.org/"
            >
              <div className="border border-gray-300 p-2 flex justify-center items-center  w-[150px] h-[150px] md:h-[200px] md:w-[200px]  rounded-md shadow-md">
                <img
                  src={aia}
                  alt="aia"
                  className="rounded-lg cursor-pointer max-h-full max-w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
              <h3 className="mt-4 text-lg font-donegal text-center">
                American Insitute of Architects (AIA)
              </h3>
            </HashLink>
            <HashLink
            target="_blank" rel="noreferrer"
              className="flex flex-col items-center justify-center"
              to="https://www.ansi.org/"
            >
              <div className="border border-gray-300 p-2 flex justify-center items-center  w-[150px] h-[150px] md:h-[200px] md:w-[200px]  rounded-md shadow-md">
                <img
                  src={ansi}
                  alt="ansi"
                  className="cursor-pointer max-h-full max-w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
              <h3 className="mt-4 text-lg font-donegal text-center">
                American Nation Standards Insitute (ANSI)
              </h3>
            </HashLink>
            <HashLink
            target="_blank" rel="noreferrer"
              className="flex flex-col items-center justify-center"
              to="https://buildershardware.com/"
            >
              <div className="border border-gray-300 p-2 flex justify-center items-center  w-[150px] h-[150px] md:h-[200px] md:w-[200px]  rounded-md shadow-md">
                <img
                  src={bhma}
                  alt="bhma"
                  className="cursor-pointer max-h-full max-w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
              <h3 className="mt-4 text-lg font-donegal text-center">
                Builder Hardware Manufracturers (BHMA)
              </h3>
            </HashLink>
            <HashLink
             target="_blank" rel="noreferrer"
              className="flex flex-col items-center justify-center"
              to="https://floridabuilding.org/"
            >
              <div className="border border-gray-300 p-2 flex justify-center items-center  w-[150px] h-[150px] md:h-[200px] md:w-[200px]  rounded-md shadow-md">
                <img
                  src={florida}
                  alt="florida"
                  className="cursor-pointer max-h-full max-w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
              <h3 className="mt-4 text-lg font-donegal text-center">
                Florida Building Commision
              </h3>
            </HashLink>
            <HashLink
              target="_blank" rel="noreferrer"
              className="flex flex-col items-center justify-center"
              to="https://www.dhi.org/"
            >
              <div className="border border-gray-300 p-2 flex justify-center items-center  w-[150px] h-[150px] md:h-[200px] md:w-[200px]  rounded-md shadow-md">
                <img
                  src={dhi}
                  alt="dhi"
                  className="cursor-pointer max-h-full max-w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
              <h3 className="mt-4 text-lg font-donegal text-center">
                Door and Hardware Institute (DHI)
              </h3>
            </HashLink>
            <HashLink
            target="_blank" rel="noreferrer"
              className="flex flex-col items-center justify-center"
              to="https://www.naamm.org/"
            >
              <div className="border border-gray-300 p-2 flex justify-center items-center  w-[150px] h-[150px] md:h-[200px] md:w-[200px]  rounded-md shadow-md">
                <img
                  src={naam}
                  alt="naam"
                  className="cursor-pointer max-h-full max-w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
              <h3 className="mt-4 text-lg font-donegal text-center">
                Hollow Metal Manufacturers Association (HMMA)
              </h3>
            </HashLink>
            <HashLink
            target="_blank" rel="noreferrer"
              className="flex flex-col items-center justify-center"
              to="https://steeldoor.org/"
            >
              <div className="border border-gray-300 p-2 flex justify-center items-center  w-[150px] h-[150px] md:h-[200px] md:w-[200px]  rounded-md shadow-md">
                <img
                  src={steel}
                  alt="steel"
                  className="cursor-pointer max-h-full max-w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
              <h3 className="mt-4 text-lg font-donegal text-center">
                Steel Door Institue (SDI)
              </h3>
            </HashLink>
            <HashLink
            target="_blank" rel="noreferrer"
              className="flex flex-col items-center justify-center"
              to="https://www.ul.com/"
            >
              <div className="border border-gray-300 p-2 flex justify-center items-center  w-[150px] h-[150px] md:h-[200px] md:w-[200px]  rounded-md shadow-md">
                <img
                  src={ulListed}
                  alt="ulListed"
                  className="cursor-pointer max-h-full max-w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
              <h3 className="mt-4 text-lg font-donegal text-center">
                Underwriter Laboratories (UL)
              </h3>
            </HashLink>
            <HashLink 
            target="_blank" rel="noreferrer"
              className="flex flex-col items-center justify-center"
              to="https://www.intertek.com/building/markets/fire-door/"
            >
              <div className="border border-gray-300 p-2 flex justify-center items-center  w-[150px] h-[150px] md:h-[200px] md:w-[200px]  rounded-md shadow-md">
                <img
                  src={whi}
                  alt="whi"
                  className="cursor-pointer max-h-full max-w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
              <h3 className="mt-4 text-lg font-donegal text-center">
                Warnock Hershey (WH)
              </h3>
            </HashLink>
            <HashLink
            target="_blank" rel="noreferrer"
              className="flex flex-col items-center justify-center"
              to="https://www.astm.org/"
            >
              <div className="border border-gray-300 p-2 flex justify-center items-center  w-[150px] h-[150px] md:h-[200px] md:w-[200px]  rounded-md shadow-md">
                <img
                  src={astm}
                  alt="astm"
                  className="cursor-pointer max-h-full max-w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
              <h3 className="mt-4 text-lg font-donegal text-center">
                ASTM Insternations
              </h3>
            </HashLink>
          </div>
        </div>
      </section>
    </div>
  )
}

export default IndustryLinks
