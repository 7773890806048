import Hero from "../components/HomeComponent/Hero"
import Card from "../components/HomeComponent/Card"
import ContactUs from "../components/HomeComponent/ContactUs"
import GetQuote from "../components/HomeComponent/GetQuote"
import Map from "../components/Map"
import WoodDoor from "../images/Door-types/WoodDoor.png"
import AlmuniumFrames from "../images/Door-types/AlmuniumFrames.png"
import Accessories from "../images/Door-types/Accesories.png"
import TrafficDoors from "../images/Door-types/TrafficDoors.png"
import MetalDoor from "../images/Door-types/MetalDoor.png"

function Home() {
  return (
    <div>
      <Hero />
      <div className="mt-10 md:mt-20 text-2xl md:text-4xl font-donegal">
        <h1 className="underline-offset-4 text-center">Our Products</h1>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 max-container">
        <Card
          image={MetalDoor}
          header="Hollow Metal Doors"
          description="
Our hollow metal doors and frames, available in any color, are built to last. These incredibly versatile doors are perfect for any setting."
          link="/products/hollow-metal-doors"
        />
        <Card
          image={WoodDoor}
          header="
Wood Doors"
          description="
Our wood doors, available with solid cores, can be painted or stained to fit any decor. We also offer a laminated option for added durability."
          link="/products/wood-doors"
        />
        <Card
          image={AlmuniumFrames}
          header="

Interior Aluminum Frames"
          description="
Our interior aluminum frames are easy to install and come in various color options. They pair nicely with wood doors."
          link="/products/interior-alumnium-frames"
        />
        <Card
          image={TrafficDoors}
          header="Traffic Doors"
          description="Lightweight, durable, available in various sizes and colors, ensuring versatility and long-lasting performance."
          link="/products/traffic-doors"
        />
        <Card
          image={Accessories}
          header="Hardware & Accessories"
          description="We offer a broad range of hardware and accessories from multiple brands, all made from durable materials to meet your needs."
          link="/products/hardware"
        />
      </div>
      <ContactUs />
      <GetQuote />
      <Map />
    </div>
  )
}

export default Home
