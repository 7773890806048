import React from "react";
import { Link } from "react-router-dom";

const ProductCard = (props) => {
  const { image, header, link } = props;
  return (
    <div className="flex flex-col">
      <div className="bg-[#e7e7e7f8] py-6 flex flex-col max-w-sm rounded mt-10 md:mt-20 justify-between">
        <Link to={link}>
          <img className="h-60 object-cover mx-auto" src={image} alt={header} />
        </Link>
      </div>
      <Link to={link}>
        <div className="mt-6 text-base md:text-lg mb-2 flex justify-center font-donegal">
          {header}
        </div>
      </Link>
    </div>
  );
};

export default ProductCard;

//e7e7e7f8
