import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";

const GetQuote = () => {
  const form = useRef();
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const schema = z.object({
    user_name: z.string().min(1, "Full Name is required"),
    user_email: z.string().email("Invalid email address"),
    user_phone: z
      .string()
      .min(10, "Phone number must be at least 10 digits")
      .max(15, "Phone number must be at most 15 digits"),
    subject: z.string().min(1, "Subject is required"),
    message: z.string().min(1, "Message is required"),
    agree_terms: z.boolean().refine((val) => val, {
      message: "You must agree to the terms and conditions",
    }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  const sendEmail = async (data) => {
    setLoading(true);
    setSuccessMessage("");
    setErrorMessage("");

    try {
      const response = await fetch("https://steller-door-backend-ten.vercel.app/api/sendEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();

      if (result.error) {
        setErrorMessage(result.error);
      } else {
        setSuccessMessage(result.message);
      }
    } catch (error) {
      console.error("Error sending email:", error);
      setErrorMessage("Something went wrong. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div id="getQuote" className="mt-12 px-4 max-container">
      <h2 className="font-donegal text-2xl md:text-4xl text-center">
        Get A Quote
      </h2>
      <p className="mt-4 text-center">
        Do you have architectural plans? Please submit those via e-mail to:{" "}
        <a
          href="mailto:sales@stellardandh.com"
          className="underline cursor-pointer"
        >
          sales@stellardandh.com
        </a>
      </p>
      <div className="mt-12 mx-auto w-full max-w-[550px]">
        <form ref={form} onSubmit={handleSubmit(sendEmail)}>
          <div className="mb-5">
            <label
              htmlFor="user_name"
              className="mb-3 block font-medium text-[#07074D]"
            >
              Full Name
            </label>
            <input
              type="text"
              name="user_name"
              id="user_name"
              placeholder="Full Name"
              className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium outline-none focus:border-[#276897] focus:shadow-md"
              {...register("user_name")}
            />
            {errors.user_name && (
              <p className="text-red-600">{errors.user_name.message}</p>
            )}
          </div>
          <div className="mb-5">
            <label
              htmlFor="user_email"
              className="mb-3 block font-medium text-[#07074D]"
            >
              Email Address
            </label>
            <input
              type="email"
              name="user_email"
              id="user_email"
              placeholder="example@domain.com"
              className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium outline-none focus:border-[#276897] focus:shadow-md"
              {...register("user_email")}
            />
            {errors.user_email && (
              <p className="text-red-600">{errors.user_email.message}</p>
            )}
          </div>
          <div className="mb-5">
            <label
              htmlFor="user_phone"
              className="mb-3 block font-medium text-[#07074D]"
            >
              Phone Number
            </label>
            <input
              name="user_phone"
              id="user_phone"
              placeholder="1234567890"
              className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium outline-none focus:border-[#276897] focus:shadow-md"
              {...register("user_phone")}
            />
            {errors.user_phone && (
              <p className="text-red-600">{errors.user_phone.message}</p>
            )}
          </div>
          <div className="mb-5">
            <label
              htmlFor="subject"
              className="mb-3 block font-medium text-[#07074D]"
            >
              Subject
            </label>
            <input
              type="text"
              name="subject"
              id="subject"
              placeholder="Enter your subject"
              className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium outline-none focus:border-[#276897] focus:shadow-md"
              {...register("subject")}
            />
            {errors.subject && (
              <p className="text-red-600">{errors.subject.message}</p>
            )}
          </div>
          <div className="mb-5">
            <label
              htmlFor="message"
              className="mb-3 block font-medium text-[#07074D]"
            >
              Message
            </label>
            <textarea
              rows="4"
              name="message"
              id="message"
              placeholder="Type your message"
              className="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium outline-none focus:border-[#276897] focus:shadow-md"
              {...register("message")}
            ></textarea>
            {errors.message && (
              <p className="text-red-600">{errors.message.message}</p>
            )}
          </div>
          <div className="mb-5 flex items-start">
            <input
              type="checkbox"
              name="agree_terms"
              id="agree_terms"
              className="mr-2 mt-1"
              {...register("agree_terms")}
            />
            <label htmlFor="agree_terms" className="font-medium text-[#07074D]">
              I agree to the terms and conditions.{" "}
              <span>
                <a
                  href="/more/privacy-policy"
                  className="underline cursor-pointer"
                >
                  Privacy Policy
                </a>
              </span>
            </label>
          </div>
          {errors.agree_terms && (
            <p className="text-red-600 text-right">
              {errors.agree_terms.message}
            </p>
          )}
          {successMessage && (
            <p className="text-green-600 text-center">{successMessage}</p>
          )}
          {errorMessage && (
            <p className="text-red-600 text-center">{errorMessage}</p>
          )}
          <input
            className={`mt-8 w-full hover:shadow-form rounded-md py-3 px-8 font-semibold text-white outline-none cursor-pointer ${
              loading ? "bg-gray-500" : "bg-[#276897]"
            }`}
            type="submit"
            value={loading ? "Sending..." : "Send"}
            disabled={loading}
          />
        </form>
      </div>
    </div>
  );
};

export default GetQuote;
