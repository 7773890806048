import React from "react"
import FlushDoor from "../../images/hollow-metal-door/flush.png"
import VisionPanel from "../../images/hollow-metal-door/vision_panel.png"
import SixPanels from "../../images/hollow-metal-door/six_panels.png"
import NarrowLite from "../../images/hollow-metal-door/narrow_lite.png"
import HalfGlass from "../../images/hollow-metal-door/half_glass.png"
import FullLouver from "../../images/hollow-metal-door/full_louver.png"
import FullGlass from "../../images/hollow-metal-door/full_glass.png"
import BottomLouver from "../../images/hollow-metal-door/bottom_louver.png"
import { GoDash } from "react-icons/go"
import { Link } from "react-router-dom"

const HollowMetalDoor = (props) => {
  return (
    <div  className="max-container mt-10 md:mt-20">
      <h1 className="text-2xl md:text-4xl  font-donegal">Hollow Metal Doors</h1>

      <div className="mt-3 flex items-center">
        <GoDash />
        <p className="mx-2 text-[#276897] font-donegal">
          Our comprehensive line of metal doors ranges from cost efficient doors
          to fire rated and lead lined doors.
        </p>
      </div>

      <div className="mt-16 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 gap-y-14 ">
        <div className="w-full text-center ">
          <img src={FlushDoor} className="w-[30%] mx-auto" />
          <h3 className="mt-4 font-donegal">Flush Doors</h3>
        </div>
        <div className="w-full text-center ">
          <img src={VisionPanel} className="w-[30%] mx-auto" />
          <h3 className="mt-4 font-donegal">Vision Panel</h3>
        </div>
        <div className="w-full text-center ">
          <img src={SixPanels} className="w-[30%] mx-auto" />
          <h3 className="mt-4 font-donegal">Six Panels</h3>
        </div>
        <div className="w-full text-center ">
          <img src={NarrowLite} className="w-[30%] mx-auto" />
          <h3 className="mt-4 font-donegal">Narrow Lite</h3>
        </div>
        <div className="w-full text-center ">
          <img src={HalfGlass} className="w-[30%] mx-auto" />
          <h3 className="mt-4 font-donegal">Half Glass</h3>
        </div>
        <div className="w-full text-center ">
          <img src={FullLouver} className="w-[30%] mx-auto" />
          <h3 className="mt-4 font-donegal">Full Louver</h3>
        </div>
        <div className="w-full text-center ">
          <img src={FullGlass} className="w-[30%] mx-auto" />
          <h3 className="mt-4 font-donegal">Full Glass</h3>
        </div>
        <div className="w-full text-center ">
          <img src={BottomLouver} className="w-[30%] mx-auto" />
          <h3 className="mt-4 font-donegal">Bottom Louver</h3>
        </div>
      </div>

      <h2 className="mt-16 text-xl font-donegal">Available Options</h2>
      <section class="grid grid-cols-1 lg:grid-cols-4 gap-x-8 gap-y-4 mt-6">
        <div class="flex flex-col justify-center">
          <div class="flex flex-col h-full shadow justify-between rounded-lg pb-8 p-6 xl:p-8 mt-3 bg-gray-50">
            <div>
              <h4 class=" font-bold text-lg leading-tight">Guage</h4>
              <div class="my-4">
                <p>20, 18, 16 Ga - Various gauge options available.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col justify-center">
          <div class="flex flex-col h-full shadow justify-between rounded-lg pb-8 p-6 xl:p-8 mt-3 bg-gray-50">
            <div>
              <h4 class=" font-bold text-lg leading-tight">Galvanized</h4>
              <div class="my-4">
                <p>
                  Features a protective zinc coating for enhanced corrosion
                  resistance.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col justify-center">
          <div class="flex flex-col h-full shadow justify-between rounded-lg pb-8 p-6 xl:p-8 mt-3 bg-gray-50">
            <div>
              <h4 class=" font-bold text-lg leading-tight">
                Custom Hardware Preps
              </h4>
              <div class="my-4">
                <p>
                  Provides tailored preparations for specific hardware
                  requirements.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col justify-center">
          <div class="flex flex-col h-full shadow justify-between rounded-lg pb-8 p-6 xl:p-8 mt-3 bg-gray-50">
            <div>
              <h4 class=" font-bold text-lg leading-tight">
                Custom Hinge Location
              </h4>
              <div class="my-4">
                <p>
                  Allows flexibility in choosing the location of hinges based on
                  custom needs.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col justify-center">
          <div class="flex flex-col h-full shadow justify-between rounded-lg pb-8 p-6 xl:p-8 mt-3 bg-gray-50">
            <div>
              <h4 class=" font-bold text-lg leading-tight">Seamless Edges</h4>
              <div class="my-4">
                <p>Ensures smooth and seamless edges for a refined finish.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col justify-center">
          <div class="flex flex-col h-full shadow justify-between rounded-lg pb-8 p-6 xl:p-8 mt-3 bg-gray-50">
            <div>
              <h4 class=" font-bold text-lg leading-tight">
                Special Reinforcements
              </h4>
              <div class="my-4">
                <p>
                  Offers additional reinforcement options to enhance structural
                  strength.
                </p>
              </div>
            </div>
          </div>
        </div>
      
      </section>
      <Link to="/products/hollow-metal-frames">
      <button className="mt-10 py-2 px-4">Hollow Metal Frames</button>
      </Link>
    </div>
  )
}

export default HollowMetalDoor
