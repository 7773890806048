import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="max-container mt-10 md:mt-20">
      <h1 className="text-2xl md:text-4xl font-donegal">Stellar Doors & Hardware Privacy Policy</h1>
      <h2 className="text-xl md:text-2xl font-donegal mt-8 md:mt-14">Introduction</h2>
      <p className="mt-4 font-inter text-[#6B6B6B]">
        Welcome to Stellar Doors & Hardware, LLC ("we", "our", "us"). We prioritize your privacy and are dedicated to safeguarding your personal information. This privacy policy explains how we collect, use, and protect your information when you visit our website, www.stellardandh.com ("Website").
      </p>
      <h2 className="text-xl md:text-2xl font-donegal mt-6 md:mt-8">1. Data Collection</h2>
      <p className="mt-4 font-inter text-[#6B6B6B]">1.1 Personal Data.</p>
      <p className="mt-2 font-inter text-[#6B6B6B]">We may gather personal information that you provide directly to us, including:</p>
      <ul className="list-disc ml-8 mt-2 text-[#6B6B6B]">
        <li>Name</li>
        <li>Email address</li>
        <li>Phone number</li>
        <li>Mailing address</li>
      </ul>
      <p className="mt-4 font-inter text-[#6B6B6B]">1.2 Non-Personal Data.</p>
      <p className="mt-2 font-inter text-[#6B6B6B]">
        We also collect non-personal information automatically when you visit our Website, such as:
      </p>
      <ul className="list-disc ml-8 mt-2 text-[#6B6B6B]">
        <li>IP address</li>
        <li>Browser type</li>
        <li>Operating system</li>
        <li>Pages visited and duration on the Website</li>
      </ul>
      <h2 className="text-xl md:text-2xl font-donegal mt-6 md:mt-8">2. Usage of Information</h2>
      <p className="mt-4 font-inter text-[#6B6B6B]">We use the collected information for various purposes, such as to:</p>
      <ul className="list-disc ml-8 mt-2 text-[#6B6B6B]">
        <li>Respond to your inquiries and provide customer support</li>
        <li>Enhance our Website and services</li>
        <li>Send promotional emails and updates</li>
      </ul>
      <h2 className="text-xl md:text-2xl font-donegal mt-6 md:mt-8">3. Information Sharing</h2>
      <p className="mt-4 font-inter text-[#6B6B6B]">
        We do not sell, trade, or transfer your personal information to third parties, except in the following cases:
      </p>
      <ul className="list-disc ml-8 mt-2 text-[#6B6B6B]">
        <li>Service Providers: We may share your data with third-party service providers who help us operate our Website and conduct our business.</li>
        <li>Legal Obligations: We may disclose your information if required by law or in response to legitimate requests by public authorities.</li>
      </ul>
      <h2 className="text-xl md:text-2xl font-donegal mt-6 md:mt-8">4. Data Security</h2>
      <p className="mt-4 font-inter text-[#6B6B6B]">
        We use various security measures to protect your personal information. However, please note that no method of internet transmission or electronic storage is completely secure.
      </p>
      <h2 className="text-xl md:text-2xl font-donegal mt-6 md:mt-8">5. Third-Party Links</h2>
      <p className="mt-4 font-inter text-[#6B6B6B]">
        Our Website may contain links to external websites not operated by us. We have no control over the content, privacy policies, or practices of these third-party sites.
      </p>
      <h2 className="text-xl md:text-2xl font-donegal mt-6 md:mt-8">6. Children's Privacy</h2>
      <p className="mt-4 font-inter text-[#6B6B6B]">
        Our Website is not intended for children under 13. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and believe your child has provided us with personal information, please contact us, and we will take steps to delete such information.
      </p>
      <h2 className="text-xl md:text-2xl font-donegal mt-6 md:mt-8">7. Your Rights</h2>
      <p className="mt-4 font-inter text-[#6B6B6B]">You have the following rights regarding your personal data:</p>
      <ul className="list-disc ml-8 mt-2 text-[#6B6B6B]">
        <li>Access: You can request access to your personal data that we hold.</li>
        <li>Correction: You can request the correction of any incorrect personal data we hold about you.</li>
        <li>Deletion: You can request the deletion of your personal data, subject to certain conditions.</li>
        <li>Objection: You can object to our processing of your personal data.</li>
      </ul>
      <h2 className="text-xl md:text-2xl font-donegal mt-6 md:mt-8">8. Changes to This Privacy Policy</h2>
      <p className="mt-4 font-inter text-[#6B6B6B]">
        We may update this privacy policy periodically. We will notify you of any changes by posting the updated policy on this page. We recommend reviewing this policy periodically for any updates.
      </p>
      <h2 className="text-xl md:text-2xl font-donegal mt-6 md:mt-8">9. Contact Us</h2>
      <p className="mt-4 font-inter text-[#6B6B6B]">If you have any questions about this privacy policy, please contact us at:</p>
      <p className="font-inter text-[#6B6B6B]"><a href="/">Stellar Doors & Hardware, LLC</a></p>
      <p className="font-inter text-[#6B6B6B]">
        <a href="mailto:sales@stellardandh.com">Email: sales@stellardandh.com</a>
      </p>
      <p className="font-inter text-[#6B6B6B]">
        <a href="tel:+18325765814">Phone: 832-576-5814</a>
      </p>
      <p className="font-inter text-[#6B6B6B]">
        <a href="https://maps.google.com/?q=5858+Cunningham+Rd,+Houston,+TX+77041">Address: 5858 Cunningham Rd, Houston, TX 77041</a>
      </p>
    </div>
  );
};

export default PrivacyPolicy;