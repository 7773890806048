import React from "react"
import { FaArrowRight } from "react-icons/fa"
import { Link } from "react-router-dom"

const Card = (props) => {
  const { image, header, description, link } = props
  return (
    <div className=" bg-[#eeeeeed4] pb-6 md:pb-10 flex flex-col max-w-sm rounded overflow-hidden shadow-xl mt-10 md:mt-20 justify-between">
      <img className="md:h-96 object-cover" src={image} />
      <div className="px-6 py-4">
        <div className="text-xl md:text-2xl mb-2 font-donegal flex justify-center">
          {header}
        </div>
        <p className="text-gray-700 text-center md:text-base text-base font-inter mt-4 md:mt-0">
          {description}
        </p>
        <div className="flex items-center mt-2 mx-auto">
          <div className="mx-auto flex items-center  gap-2">
            <Link to={link} className="flex items-center text-[#276897] gap-2 ">
              <span>Read More</span>
              <FaArrowRight className="text-gray-600" />
            </Link>
          </div>
        </div>
      </div>
      <div className="px-6 pt-4 pb-2 mx-auto ">
        <a href="#getQuote">
          <button className="p-3 text-sm mr-2 ">Get a Quote</button>
        </a>
      </div>
    </div>
  )
}

export default Card
