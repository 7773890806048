import React from "react"
import type1 from "../../images/Alumnium-frames/type1.png"
import type2 from "../../images/Alumnium-frames/type2.png"

const InteriorAlumnium = () => {
  return (
    <div className="max-container mt-10 md:mt-20">
      <h1 className="text-2xl md:text-4xl font-donegal">
        Interior Aluminum Frames
      </h1>
      <div className="bg-gray-100 flex flex-col md:flex-row px-8 md:px-20 py-10 w-full rounded overflow-hidden shadow-md mt-10  ">
        <img className="h-72 object-cover " src={type1} />
        <div className=" flex flex-col items-center w-full md:w-[80%] mx-auto">
          <div className=" mt-4 md:mt-0 font-semibold text-xl md:text-2xl leading-tight font-donegal pb-2">
            Type I
          </div>
          <p className="mt-6 md:mt-10  leading-relaxed md:leading-[1.9]">
            The Type I aluminum frame system is renowned for its straightforward
            installation process, involving direct screwing into sheet-rock
            followed by taping and floating for a seamless finish. Ideal for
            interior applications, this system ensures both stability and ease
            of installation. It accommodates 1/4-inch and 3/8-inch glazing
            options, offering flexibility in design and functionality to meet
            diverse aesthetic and performance requirements, making it a
            versatile and practical choice for interior architectural projects.
          </p>
        </div>
      </div>
      <div className="bg-gray-100  flex flex-col md:flex-row px-8 md:px-20 py-10 w-full rounded overflow-hidden shadow-md mt-10  ">
        <img className="h-64 object-cover " src={type2} />
        <div className=" flex  flex-col items-center w-full md:w-[80%] mx-auto">
          <div className="mt-6 md:mt-0 font-semibold text-xl md:text-2xl leading-tight font-donegal pb-2">
            Type II
          </div>
          <p className="mt-6 md:mt-10 leading-relaxed md:leading-[1.9] md:pl-4">
            The Type II aluminum framing system is designed for superior
            versatility, strength, and ease of installation. It features snap-on
            trim that conceals fasteners, providing a clean and modern
            aesthetic. These frames are installed after walls are finished and
            painted, accommodating glazing options of 1/4", 3/8", and 1/2"
            thicknesses. The system's modular design allows for flexible
            configuration and adaptation to various architectural needs. It
            combines robust construction with seamless integration.
          </p>
        </div>
      </div>
    </div>
  )
}

export default InteriorAlumnium
